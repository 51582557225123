/* eslint-disable no-useless-escape */
import cn from 'classnames'
import { TFunction } from 'i18next'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import debounce from "lodash/debounce"
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { renderToString } from 'react-dom/server'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { clearTimeout } from 'timers'
import { useMenuEntries } from '../components/Container/Dashboard/DashboardSideBar/items'
import { DashboardMenuItem } from '../components/Container/Dashboard/DashboardSideBar/utils'
import HttpLink from '../components/HttpLink'
import { Phone } from '../components/Presentational/PhoneInput'
import { appConfig } from '../config'
import { useIBState } from '../ib/utils/hooks'
import { getQuestions, logout, sendLastField } from '../redux/actions'
import { AppState } from '../redux/appState'
import { appError, setPageTitle, setUserAppInfo, setUserInsidePortal } from '../redux/slices/app'
import { Store } from '../redux/store'
import api from './api'
import { mobileDevice } from './constants/browser'
import { NON_MINI_TMBM_COUNTRIES } from './constants/NON_MINI_TMBM_COUNTRIES'
import { AccountsSort, AccountTradingTypes, allJapaneseSymbolsRegex, kanjiNameRegex, katakanaNameRegex, PlatformMap, PortalAccountDomain, ScreenSize, TradeAccountType, WTRLanguage, __STORAGE } from './enums'
import { useVisualSteps } from './onboarding'
import routes, { ibPath } from './routes'
import { setScrollTop } from './scroll'
import { useThemes } from './themes'
import { Account, AccountStat, AccountTradingTypeModel, AccountTradingTypeUnion, AddressFields, AppInfo, ApplicationStatus, Country, DocTypes, DocTypesMap, FieldValidation, IndexedObject, QuestionsIDs, RemainingDeposit, SelectOption, TradingAccountType, TradingPlatform, TradingPlatformID, TradingPlatformName, UserApplication, WtrAdditionalParams } from './types'
import { useCurrencyName } from './useCurrencyName'
import Utils from './utils'

function usePrevious(value: any) {
    const ref = useRef(null)
    useEffect(() => {
        ref.current = value
    }, [value])
    return ref.current
}

const useShortTranslation = (languageString: string) => {
    const { t } = useTranslation()
    const tt = (name: string, options?: Object) => {
        return t(`${languageString}${name}`, options)
    }
    return tt
}

const useScrollToTop = () => {
    useEffect(() => {
        setScrollTop(0)
    }, [])
}

const usePageTitle = (key: string | null) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setPageTitle(key))
        return () => { dispatch(setPageTitle(null)) }
    }, [dispatch, key])
}

const useThousandsFormatter = (minimumFractionDigits: number = 2, maximumFractionDigits: number = 8) => {
    const getFormat = useCallback((currency?: string, minDecimals: number = 2, maxDecimals: number = 8) =>
    ({
        style: 'currency',
        currency,
        currencyDisplay: 'code',
        minimumFractionDigits: typeof minDecimals === 'number' ? minDecimals : minimumFractionDigits,
        maximumFractionDigits: typeof maxDecimals === 'number' ? maxDecimals : maximumFractionDigits
    }), [minimumFractionDigits, maximumFractionDigits])
    const language = useSelector((state: Store) => state.App.language)

    const thousandsFormatter = useCallback((value: number, currency: string = 'USD', minDecimals: number = 2, maxDecimals: number = 8) => {
        return value.toLocaleString(language, getFormat(currency, minDecimals, maxDecimals)).replace(/[a-z]{3}/i, "").trim()
    }, [language, getFormat])

    return thousandsFormatter
}

const useDateTimeFormatter = () => {
    const { portalAccountDomain } = useSelector((state: Store) => state.App)
    const isTMJPaccount = PortalAccountDomain.TMJP === portalAccountDomain

    const dateTimeFormatter = useCallback((value?: string, isTimeIncluded: boolean = false) => {
        const utcDate = moment.utc(value)
        const base = isTMJPaccount ? 'YYYY年MM月DD日' : 'DD/MM/YYYY'
        const formatPattern = isTimeIncluded ? `${base} HH:mm` : base
        return moment(utcDate).local().format(formatPattern)
    }, [isTMJPaccount])

    return dateTimeFormatter
}

const useCurrencyFormatter = (currencyCode?: string) => {
    const thousandsFormatter = useThousandsFormatter()

    const formatCurrency = useCallback((value: number, code?: string) => {
        try {
            const resultCurrency = code || currencyCode
            if (resultCurrency && ['BTC', 'ETH', 'DASH', 'BCH', 'LTC', 'USDT', 'XLM', 'EOS', 'XRP', 'USDC'].includes(resultCurrency.toUpperCase())) {
                return Utils.fixToSpecificDecimal(value || 0, 8)
            }
            if (resultCurrency === 'JPY') {
                return thousandsFormatter(value || 0, resultCurrency, 0, 0)
            }
            return thousandsFormatter(Utils.floorToTwoDecimals(value || 0), resultCurrency)
        } catch (error) {
            return String(value)
        }
    }, [currencyCode, thousandsFormatter])

    return formatCurrency
}

const useFormatAmount = (baseCurrency?: string, isBehind: boolean = true) => {
    const formatter = useCurrencyFormatter()

    const format = useCallback((value: number, code?: string) => {
        const currency = code || baseCurrency

        const valueSpan = <span data-di-mask data-dd-privacy="mask" className="amountClass">{formatter(value, currency)}</span>
        const signSpan = <span>{Utils.getCurrencySign(currency)}</span>
        return isBehind ? (
            <span>
                {valueSpan}
                &nbsp;
                {signSpan}
            </span>
        ) : (
            <span>
                {signSpan}
                &nbsp;
                {valueSpan}
            </span>
        )
    }, [baseCurrency, formatter, isBehind])

    return format
}

const UseLinks = (links: IndexedObject | string) => {
    return (name: string) => {
        if (Utils.isObject(links)) {
            const link = (links as IndexedObject)[name]
            if (link) {
                return <HttpLink url={link.url}>{link.name}</HttpLink>
            }
            else {
                return name
            }
        }
        return name
    }
}


export const useDocTypeTitle = () => {
    const { t } = useTranslation()
    const languageString = 'account:upload'
    const portalAccountDomain = useSelector((state: Store) => state.App.portalAccountDomain)
    const userProfile = useSelector((state: Store) => state.App.userProfile)
    const isSouthAfrica = userProfile?.country.code3 === "ZAF"
    const isThailand = userProfile?.country.code3 === 'THA'

    const getDocTypeTitle = useCallback((type?: DocTypes): string => {
        let docTitleLangString = isSouthAfrica
            ? `${languageString}.documents.TFSA`
            : `${languageString}.documents.${portalAccountDomain}`
        if (isSouthAfrica) docTitleLangString += '.ZAF'
        if (portalAccountDomain === PortalAccountDomain.TMNZ)
            docTitleLangString = `${languageString}.documents.AU`
        if (portalAccountDomain === PortalAccountDomain.TMLC)
            docTitleLangString = `${languageString}.documents.TMBM`
        switch (type) {
            case 'passport': return t(`${docTitleLangString}.passport`)
            case 'license': {
                if (isThailand) {
                    return t(`${docTitleLangString}.THA.driverLicence`)
                }
                return t(`${docTitleLangString}.driverLicence`)
            }
            case 'id': {
                if (Utils.checkDIFCentity(userProfile?.country, portalAccountDomain))
                    return t(`${docTitleLangString}.uaeNationalID`)
                return t(`${docTitleLangString}.nationalID`)
            }
            case 'address': return t(`${docTitleLangString}.bill`)
            case 'bank': return t(`${docTitleLangString}.bankStatement`)
            case 'card': return t(`${docTitleLangString}.creditCardStatement`)
            case 'other': return t(`${docTitleLangString}.other`)
            case 'photo': return t(`${docTitleLangString}.photo`)
            case 'photoSecondary': return t(`${docTitleLangString}.photoSecondary`)
            case 'incorporationArticles': return t(`${docTitleLangString}.incorporationArticles`)
            case 'addressSecondary': return t(`${docTitleLangString}.addressSecondary`)
            case 'addressCompany': return t(`${docTitleLangString}.addressCompany`)
            case 'addressCorporation': return t(`${docTitleLangString}.addressCorporation`)
            case 'postOfficeBox': return t(`${docTitleLangString}.postOfficeBox`)
            case 'avatar': return t(`${docTitleLangString}.avatar`)
            case 'visa': return t(`${docTitleLangString}.visa`)
            case 'Proof of ID': return t(`${docTitleLangString}.Proof of ID`)
        }
        // fallback to english
        return type ? (DocTypesMap as any)[type].name : ''
    }, [t, portalAccountDomain, isSouthAfrica, isThailand, userProfile?.country])

    return getDocTypeTitle
}

export const useDocTypeDescription = () => {
    const { t } = useTranslation()
    const languageString = 'account:upload.documentsDescription'
    const userProfile = useSelector((state: Store) => state.App.userProfile)
    const isPhilippines = userProfile?.country.code3 === "PHL"

    const getDocTypeDescription = useCallback((type?: DocTypes): string => {
        if (isPhilippines) {
            let docTitleLangString = `${languageString}.PHL`
            switch (type) {
                case 'id': return t(`${docTitleLangString}.acceptedDocumentTypes`)
            }
        }
        return ''
    }, [t, isPhilippines])

    return getDocTypeDescription
}

const useIsGeneralSidebarEnabled = () => {
    const store = useSelector((state: Store) => state.App)
    const { liveAppCompleted, demoAccounts, appStatus, hasCompletedApp } = store

    const enabled = demoAccounts.length
        ? true
        : hasCompletedApp || liveAppCompleted || ['APPROVED',
            'PENDING_REVIEW',
            'DENIED',
            'FAILED',
            'PENDING_TRANSFER',
            'PENDING_ID_ADDRESS',
            'PENDING_KYC',
            'MANUAL_DOCS',
            'LEVEL1_APPROVED',
            'PENDING_ID',
            'PENDING_ADDRESS',
            'INTERNAL_TESTING',
            'PENDING_NEW_TnC_ACCEPTANCE',
            'PENDING_IP_MISMATCH',
            'PENDING_DOB_MISMATCH',
            'PENDING_BLURRY_POA',
            'PENDING_BLURRY_CROPPED_ID',
            'PENDING_TRANSLATION',
            'PENDING_APPROPRIATENESS_TEST'].includes(appStatus!)
    return enabled
}

const usePlatformName = () => {
    const { t } = useTranslation()
    return useCallback((name?: TradingPlatformName): string => {
        if (!name) return ''
        switch (name) {
            case 'MT4': return 'MetaTrader 4'
            case 'MT5': return 'MetaTrader 5'
            default: return t(`common:platformName.${name}`)
        }
    }, [t])
}

const use2FACheck = () => {
    const history = useHistory()
    const { twoFa: { checked, enabled, valid } } = useAppState()

    const check = useCallback(() => {
        if (checked) {
            if (enabled && !valid) {
                history.push(routes.account.check2fa)
                return false
            }
            return true
        }
        return false
    }, [checked, enabled, history, valid])

    return check
}

const useUserApplications = () => {
    const { uncompletedApplication, userApps, demoAccounts,
        accountsLoaded, loggedIn, userCountry } = useAppState()

    const validApps = useMemo(() => {
        return userApps.every(a => a.accountTradingType)
    }, [userApps])

    const hasNoApplication = useMemo(() => userApps.length === 0, [userApps.length])

    const hasCEAccount = useMemo(() => {
        return validApps
            ? userApps.find(x => x.accountTradingType.accountTradingType === 'CE') !== undefined
            : false
    }, [userApps, validApps])

    const hasCFDAccount = useMemo(() => {
        return validApps
            ? userApps.find(x => x.accountTradingType.accountTradingType === 'CFD') !== undefined
            : false
    }, [userApps, validApps])

    const incompleteApplication = useMemo(() => {
        return userApps.find(x => x.applicationStatus === 'INCOMPLETE')
    }, [userApps])

    const levelOneApprovedApp = useMemo(() => {
        return userApps.find(x => x.applicationStatus === 'LEVEL1_APPROVED')
    }, [userApps])

    const simplifiedAppNotApprovedYet = useMemo(() => {
        if (userCountry?.isSimplifyOnboarding) {
            const hasApproved = userApps.find(x => x.applicationStatus === 'APPROVED')
            if (hasApproved) return false

            const resultApp = userApps.find(x => x.applicationStatus !== 'APPROVED')
            if (resultApp?.platformAccountType === 'PAMM_Master') {
                return false
            }
            return !!resultApp
        }
        return false
    }, [userApps, userCountry?.isSimplifyOnboarding])

    const hasSimplifiedAppExpired = useMemo(() => {
        const app = userApps.find(x => x.isSimplifiedOnboarding &&
            x.applicationStatus !== 'APPROVED')
        if (!app) return false
        return moment().diff(moment(app.createdDate), 'days') > 60
    }, [userApps])


    const hasFailed = useMemo(() => {
        return userApps.find(x => [
            'PENDING_APPROPRIATENESS_TEST',
            'PENDING_RG227',
            'DENIED',
            'FAILED'
        ].includes(x.applicationStatus)) !== undefined
    }, [userApps])

    const failedApp = useMemo(() => {
        return userApps.find(x => [
            'DENIED',
            'FAILED'
        ].includes(x.applicationStatus))
    }, [userApps])

    const hasUnexpiredFailed = useMemo(() => {
        return failedApp
            ? moment().diff(moment(failedApp.createdDate), 'days') < 90
            : false
    }, [failedApp])

    const hasApproved = useMemo(() => {
        return validApps
            ? userApps.find(x => x.applicationStatus === 'APPROVED') !== undefined
            : false
    }, [userApps, validApps])

    const hasApprovedCFD = useMemo(() => {
        return validApps
            ? userApps.find(x => x.accountTradingType.accountTradingType === 'CFD'
                && x.applicationStatus === 'APPROVED') !== undefined
            : false
    }, [userApps, validApps])

    const hasPendingReview = useMemo(() => {
        return validApps
            ? userApps.find(x => x.applicationStatus === 'PENDING_REVIEW') !== undefined
            : false
    }, [userApps, validApps])

    const hasThinkCopy = useMemo(() => {
        return validApps
            ? userApps.find(x => x.platformAccountType === 'CFD_Copy') !== undefined
            : false
    }, [userApps, validApps])

    const introducedBy = useMemo(() => {
        return validApps
            ? userApps.find(x => x.introducedBy !== undefined)?.introducedBy
            : undefined
    }, [userApps, validApps])

    const SBApplication = useMemo(() => {
        return validApps
            ? userApps.find(x => x.platformAccountType === 'spreadBetting')
            : undefined
    }, [userApps, validApps])

    const hasPAMMMaster = useMemo(() => {
        return validApps
            ? userApps.find(x => x.platformAccountType === 'PAMM_Master') !== undefined
            : false
    }, [userApps, validApps])

    const hasPAMMInvestor = useMemo(() => {
        return validApps
            ? userApps.find(x => x.platformAccountType === 'PAMM_Investor') !== undefined
            : false
    }, [userApps, validApps])

    const hasApprovedCE = useMemo(() => {
        return validApps
            ? userApps.find(x => x.accountTradingType?.accountTradingType === 'CE'
                && x.applicationStatus === 'APPROVED') !== undefined
            : false
    }, [userApps, validApps])

    const notApprovedCFD = useMemo(() => {
        return userApps.filter(x => x.accountTradingType?.accountTradingType === 'CFD'
            && !['APPROVED', 'DUPLICATE'].includes(x.applicationStatus))
    }, [userApps])

    const notApprovedCE = useMemo(() => {
        return userApps.filter(x => x.accountTradingType?.accountTradingType === 'CE'
            && !['APPROVED', 'DUPLICATE'].includes(x.applicationStatus))
    }, [userApps])

    const notApprovedApps = useMemo(() => {
        return [...notApprovedCFD, ...notApprovedCE]
    }, [notApprovedCFD, notApprovedCE])

    const hasCFDApps = useMemo(() => {
        if (validApps) {
            const apps = userApps.filter(x => x.accountTradingType?.accountTradingType === 'CFD')
            return apps.length && apps.length > 0
        }
        return true
    }, [userApps, validApps])

    const hasCEApps = useMemo(() => {
        if (validApps) {
            const apps = userApps.filter(x => x.accountTradingType?.accountTradingType === 'CE')
            return apps!.length && apps!.length > 0
        }
        return true
    }, [userApps, validApps])

    const latestApplication = useMemo(() => {
        return userApps.slice().sort((x, y) => Utils.sortDates('descend', x.createdDate, y.createdDate))[0]
    }, [userApps])

    const internalApplication = useMemo(() => loggedIn &&
        (hasApproved ||
            (accountsLoaded && demoAccounts.length > 0)),
        [accountsLoaded, demoAccounts.length, hasApproved, loggedIn])

    const hasOngoingApplication = useMemo(() => {
        return !!userApps.find(x => !['APPROVED', 'DENIED', 'FAILED', 'ALREADY_REGISTERED', 'DUPLICATE']
            .includes(x.applicationStatus))
    }, [userApps])

    const pendingDocsApplication = useMemo(() => {
        return userApps.find(x => ['PENDING', 'PENDING_ID_ADDRESS', 'PENDING_ID', 'PENDING_ADDRESS', 'PENDING_PROPER_DOCUMENTS', 'PENDING_E_VERIFICATION_RETRY', 'PENDING_KYC', 'MANUAL_DOCS']
            .includes(x.applicationStatus))
    }, [userApps])

    const userPlatforms = useMemo<TradingPlatformName[]>(() => {
        return userApps ? userApps.map(a => PlatformMap[a.platformId])
            : []
    }, [userApps])

    return {
        hasCEAccount,
        hasCFDAccount,
        hasApprovedCFD,
        hasApprovedCE,
        hasCFDApps,
        hasCEApps,
        incompleteApplication,
        levelOneApprovedApp,
        simplifiedAppNotApprovedYet,
        hasPendingReview,
        hasFailed,
        latestApplication,
        internalApplication,
        hasApproved,
        hasNoApplication,
        notApprovedCFD,
        notApprovedCE,
        uncompletedApplication,
        hasOngoingApplication,
        userPlatforms,
        notApprovedApps,
        failedApp,
        hasUnexpiredFailed,
        validApps,
        hasPAMMMaster,
        hasPAMMInvestor,
        hasThinkCopy,
        hasSimplifiedAppExpired,
        pendingDocsApplication,
        SBApplication,
        introducedBy
    }
}

const useCompleteAppLaterButton = () => {
    const { t } = useTranslation('account')
    const dispatch = useDispatch()
    const history = useHistory()
    const completeLater = useCallback(() => {
        dispatch(setUserInsidePortal(true))
        history.push(routes.dashboard.accounts.root)
    }, [dispatch, history])

    return <button
        className={cn('button', 'black-border')}
        onClick={completeLater}
    >{t('completeLater')}</button>
}

const useGetAccountTypesOptions = (t: TFunction, tradingTypes: AccountTradingTypeModel[]) => {
    // const shouldAllowCE = !!tradingTypes.find(x => x.accountTradingType === 'CE')

    const accountTypes: SelectOption[] = useMemo(() => {
        const available = [{
            name: t('account:type.individual'),
            value: 'individual'
        }, {
            name: t('account:type.corporate'),
            // hint: shouldAllowCE ? t('account:corporateCEwarning') : undefined,
            hint: t('account:corporateCEwarning'),
            hintPlace: 'right' as ReactTooltip.Place,
            value: 'corporate',
            disabled: true
        }]
        return available
    }, [t])

    return accountTypes
}

const useAccounts = () => {
    const store: AppState = useSelector((state: Store) => state.App)
    const { latestApplication } = useUserApplications()
    const { liveAccounts, demoAccounts } = store
    const availabeForDeposit = useMemo(() => Utils.getAccountsForDeposit(liveAccounts), [liveAccounts])
    const availabeForTransfer = useMemo(() => Utils.getAccountsForTransferOrWithdraw(liveAccounts), [liveAccounts])
    const enabledLiveAccounts = useMemo(() => liveAccounts.filter(a => !a.archived &&
        a.platformAccountType !== "PAMM_Master")
        , [liveAccounts])
    const totalLiveAccounts = useMemo(() => liveAccounts.filter(a => !a.archived)
        , [liveAccounts])
    const enabledDemoAccounts = useMemo(() => demoAccounts.filter(a => !a.archived), [demoAccounts])
    const allEnabledAccounts = useMemo(() => [...enabledLiveAccounts, ...enabledDemoAccounts], [enabledDemoAccounts, enabledLiveAccounts])
    const allLiveEnabledTTcfds = useMemo(() => enabledLiveAccounts.filter(x => x.account.accountTradingType.accountTradingType === 'CFD'
        && x.account.platform.name === 'ThinkTrader'), [enabledLiveAccounts])
    const hasLiveAccounts = useMemo(() => liveAccounts.length > 0, [liveAccounts])
    const hasDemoAccounts = useMemo(() => demoAccounts.length > 0, [demoAccounts.length])
    const latestLiveAccount = useMemo(() => liveAccounts.find(x =>
        x.account.accountTradingType.id === latestApplication?.accountTradingType?.id), [liveAccounts, latestApplication])
    const isDemo = useMemo(() => hasDemoAccounts && !hasLiveAccounts, [hasDemoAccounts, hasLiveAccounts])
    const hasFundedLiveAccount = useMemo(() => !!liveAccounts.find(x => !x.archived && x.balance > 0), [liveAccounts])
    const TTcfdsWithBalance = useMemo(() => allLiveEnabledTTcfds.filter(account => account.balance > 0), [allLiveEnabledTTcfds])
    const hasThinkCopy = useMemo(() => !!allEnabledAccounts.find(a => a.account.platform.code === 'THINK_COPY'), [allEnabledAccounts])
    const hasPAMMMaster = useMemo(() => !!liveAccounts.find(a => a.platformAccountType === 'PAMM_Master'), [liveAccounts])
    const PAMMMasterLimitReached = useMemo(() =>
        liveAccounts.filter(a => a.platformAccountType === 'PAMM_Master').length >= 1, [liveAccounts])
    const hasPAMMInvestor = useMemo(() => !!liveAccounts.find(a => a.platformAccountType === 'PAMM_Investor'), [liveAccounts])
    const hasPAMM = useMemo(() => hasPAMMInvestor || hasPAMMMaster, [hasPAMMInvestor, hasPAMMMaster])
    const hasDemoContestAccount = useMemo(() => !!enabledDemoAccounts.find(a => a.platformAccountType === 'DemoContest'), [enabledDemoAccounts])
    const hasMini = useMemo(() => allEnabledAccounts.find(a => a.platformAccountType === 'Mini'), [allEnabledAccounts])
    const hasLiveMini = useMemo(() => liveAccounts.find(a => a.platformAccountType === 'Mini'), [liveAccounts])
    const hasLiveTT = useMemo(() => liveAccounts.find(a => a.platformAccountType === 'ThinkTrader'), [liveAccounts])
    const hasLiveJapanSpread = useMemo(() => liveAccounts.find(a => a.platformAccountType === 'JapanSpread'), [liveAccounts])
    const hasOnlyComissionsAcc = useMemo(() => liveAccounts.some(a => a.platformAccountType === 'Commission') && liveAccounts.length === 1, [liveAccounts])

    return {
        availabeForDeposit,
        availabeForTransfer,
        enabledLiveAccounts,
        enabledDemoAccounts,
        allEnabledAccounts,
        hasDemoAccounts,
        hasLiveAccounts,
        latestLiveAccount,
        isDemo,
        demoAccounts,
        liveAccounts,
        hasFundedLiveAccount,
        allLiveEnabledTTcfds,
        TTcfdsWithBalance,
        hasPAMMMaster,
        hasPAMMInvestor,
        hasPAMM,
        PAMMMasterLimitReached,
        totalLiveAccounts,
        hasThinkCopy,
        hasDemoContestAccount,
        hasMini,
        hasLiveMini,
        hasOnlyComissionsAcc,
        hasLiveTT,
        hasLiveJapanSpread,
    }
}

const useSortedAccounts = (sort: AccountsSort = AccountsSort.Default) => {
    const { liveAccounts, demoAccounts } = useAppState()

    const accounts = useMemo(() => [...liveAccounts, ...demoAccounts], [demoAccounts, liveAccounts])

    const allCFDAccounts = useMemo(() => {
        return accounts.filter(a =>
            a.account.accountTradingType.accountTradingType === "CFD"
        )
    }, [accounts])

    const enabledCFDAccounts = useMemo(() => {
        return allCFDAccounts.filter(a => !a.archived)
    }, [allCFDAccounts])

    const archivedCFDAccounts = useMemo(() => {
        return allCFDAccounts.filter(a => a.archived)
    }, [allCFDAccounts])

    const enabledShareAccounts = useMemo(() => {
        return accounts.filter(a =>
            a.account.accountTradingType.accountTradingType !== "CFD" &&
            !a.archived
        )
    }, [accounts])

    const archivedShareAccounts = useMemo(() => {
        return accounts.filter(a =>
            a.account.accountTradingType.accountTradingType !== "CFD" &&
            a.archived
        )
    }, [accounts])

    const allEnabledAccounts = useMemo(() => {
        return accounts.filter(a => !a.archived)
    }, [accounts])

    const allEnabledLiveAccounts = useMemo(() => {
        return allEnabledAccounts.filter(a => a.account.type === TradeAccountType.Live)
    }, [allEnabledAccounts])

    const allActiveLiveAccounts = useMemo(() => {
        return accounts.filter(a => a.account.type === TradeAccountType.Live &&
            !a.archived)
    }, [accounts])

    const allActiveDemoAccounts = useMemo(() => {
        return accounts.filter(a => a.account.type === TradeAccountType.Demo &&
            !a.archived)
    }, [accounts])

    const allLiveCFDAccounts = useMemo(() => {
        return allCFDAccounts.filter(a => a.account.type === TradeAccountType.Live)
    }, [allCFDAccounts])

    const allArchivedAccounts = useMemo(() => {
        return accounts.filter(a => a.archived)
    }, [accounts])

    const accountTypeOrder = useMemo<Record<TradingAccountType, number>>(() => ({
        "Mini": 1,
        "Classic": 2,
        "Zero": 3,
        "standard": 4,
        "think_invest": 5,
        "ThinkZero": 6,
        "spreadBetting": 7,
        "ThinkTrader": 8,
        "PAMM_Master": 9,
        "PAMM_Master_Rebate": 10,
        "PAMM_Investor": 11,
        "trade_interceptor": 12,
        "CFD_Copy": 13,
        "DemoContest": 14,
        "Commission": 15,
        "JapanSpread": 16,
    }), [])

    const platformOrder = useMemo<Record<TradingPlatformName, number>>(() => ({
        "ThinkTrader": 1,
        "ThinkCopy": 2,
        "MT4": 3,
        "MT5": 4,
        "PAMM_Trading": 5,
        "PAMM_TRADING": 5,
        "ThinkInvest": 6,
        "TradeInterceptor": 7,
        "THINK_COPY": 8
    }), [])

    const defaultSort = useCallback((accounts: AccountStat[]) => {
        return accounts.sort((a, b) => {
            const { platformAccountType: accountA = 'standard',
                account: { accountTradingType: { accountTradingType: productA },
                    platform: { name: platformA } } } = a
            const { platformAccountType: accountB = 'standard',
                account: { accountTradingType: { accountTradingType: productB },
                    platform: { name: platformB } } } = b
            switch (true) {
                case productA !== productB:
                    return productA === "CFD" ? 1 : -1
                case platformA !== platformB:
                    return platformOrder[platformA] - platformOrder[platformB]
                case platformA === platformB:
                    return accountTypeOrder[accountA] - accountTypeOrder[accountB]
                default: return 0
            }
        })
    }, [accountTypeOrder, platformOrder])

    const platformReverse = useCallback((accounts: AccountStat[]) => {
        return accounts.sort((a, b) => {
            const { platformAccountType: accountA = 'standard',
                account: { accountTradingType: { accountTradingType: productA },
                    platform: { name: platformA } } } = a
            const { platformAccountType: accountB = 'standard',
                account: { accountTradingType: { accountTradingType: productB },
                    platform: { name: platformB } } } = b
            switch (true) {
                case productA !== productB:
                    return productA === "CFD" ? 1 : -1
                case platformA !== platformB:
                    return platformOrder[platformB] - platformOrder[platformA]
                case platformA === platformB:
                    return accountTypeOrder[accountA] - accountTypeOrder[accountB]
                default: return 0
            }
        })
    }, [accountTypeOrder, platformOrder])

    const currencySort = useCallback((accounts: AccountStat[]) => {
        return accounts.sort((a, b) => {
            const { account: { currency: { code: currencyA } } } = a
            const { account: { currency: { code: currencyB } } } = b
            return currencyA.localeCompare(currencyB)
        })
    }, [])

    const sortedAccounts = useMemo(() => {
        switch (sort) {
            case AccountsSort.Status:
                return [
                    ...defaultSort(allArchivedAccounts),
                    ...defaultSort(allActiveDemoAccounts),
                    ...defaultSort(allActiveLiveAccounts),
                ]
            case AccountsSort.Platform:
                return [
                    ...platformReverse(allActiveLiveAccounts),
                    ...platformReverse(allActiveDemoAccounts),
                    ...platformReverse(allArchivedAccounts)
                ]
            case AccountsSort.Currency:
                return currencySort(accounts)
            default: return [
                ...defaultSort(allActiveLiveAccounts),
                ...defaultSort(allActiveDemoAccounts),
                ...defaultSort(allArchivedAccounts)
            ]
        }
    }, [accounts, allActiveDemoAccounts, allActiveLiveAccounts, allArchivedAccounts, currencySort, defaultSort, platformReverse, sort])

    const sortedDemo = useMemo(() =>
        sortedAccounts.filter(a => a.account.type === 'DEMO')
        , [sortedAccounts])

    const sortedLive = useMemo(() =>
        sortedAccounts.filter(a => a.account.type === 'LIVE')
        , [sortedAccounts])

    const sortedActiveLive = useMemo(() => {
        return sortedAccounts.filter(a => a.account.type === TradeAccountType.Live &&
            !a.archived)
    }, [sortedAccounts])


    const sortedArchivedLive = useMemo(() => {
        return sortedAccounts.filter(a => a.account.type === TradeAccountType.Live &&
            a.archived)
    }, [sortedAccounts])

    const sortedActiveDemo = useMemo(() => {
        return sortedAccounts.filter(a => a.account.type === TradeAccountType.Demo &&
            !a.archived)
    }, [sortedAccounts])

    const sortedArchivedDemo = useMemo(() => {
        return sortedAccounts.filter(a => a.account.type === TradeAccountType.Demo &&
            a.archived)
    }, [sortedAccounts])

    const sortedArchived = useMemo(() => {
        return [...sortedArchivedLive, ...sortedArchivedDemo]
    }, [sortedArchivedDemo, sortedArchivedLive])

    return {
        enabledShareAccounts,
        enabledCFDAccounts,
        archivedCFDAccounts,
        archivedShareAccounts,
        allEnabledAccounts,
        allEnabledLiveAccounts,
        allLiveCFDAccounts,
        allArchivedAccounts,
        allCFDAccounts,
        allActiveLiveAccounts,
        allActiveDemoAccounts,
        sortedAccounts,
        sortedDemo,
        sortedLive,
        sortedActiveDemo,
        sortedArchivedDemo,
        sortedActiveLive,
        sortedArchivedLive,
        sortedArchived,
        accountTypeOrder
    }

}

const useEmailValidation = (t: TFunction) => {
    const validate = useCallback((email?: string) => {
        if (!email || !email.length)
            return t('common:email.errors.notDefined')
        if (!Utils.isValidEmail(email))
            return t('common:email.errors.notValid')
        return ''
    }, [t])

    return validate
}

const useSetLastFieldName = () => {
    const lastFiled = useRef<string | undefined>(undefined)
    const dispatch = useDispatch()

    const setLastFieldName = useCallback((name: string) => {
        if (name !== lastFiled.current) {
            lastFiled.current = name
            dispatch(sendLastField(name))
        }
    }, [dispatch])
    return setLastFieldName
}

const topLevelProfileRoutes = [
    routes.profile.personalDetails,
    routes.profile.uploadDocuments,
    routes.profile.systemSettings.root,
]

const useApplicationAccountType = () => {
    const state = useAppState()
    const { uncompletedApplication: app, userProfile, userCountry } = state
    const accountTradingType = Utils.retrieveAccTradingType(app?.accountTradingTypes)

    const isAUCFD = useMemo(() => {
        return Utils.checkIfCountryIsAustralia(userProfile?.country || userCountry) &&
            accountTradingType === AccountTradingTypes.ForexAndCFDs
    }, [accountTradingType, userCountry, userProfile?.country])

    const isAUShares = useMemo(() => {
        return Utils.checkIfCountryIsAustralia(userProfile?.country || userCountry) &&
            accountTradingType === AccountTradingTypes.CashEquitiesASX
    }, [accountTradingType, userCountry, userProfile?.country])

    const isFTSAShares = useMemo(() => {
        return accountTradingType === AccountTradingTypes.CashEquitiesJSE
    }, [accountTradingType])

    const isCFD = useMemo(() => {
        return accountTradingType === AccountTradingTypes.ForexAndCFDs
    }, [accountTradingType])

    const isShares = useMemo(() => {
        return accountTradingType === AccountTradingTypes.CashEquitiesASX
    }, [accountTradingType])
    return {
        isAUCFD, isAUShares, isFTSAShares, isCFD, isShares
    }
}

const useBackButton = () => {
    const { redirectedFromWTR } = useAppState()
    const history = useHistory()
    const dispatch = useDispatch()
    const location = useLocation()
    const { loggedIn, userInsidePortal } = useAppState()
    const wtrApp = Utils.wtrAppSession() || redirectedFromWTR
    const { prevStep } = useVisualSteps()

    const handleBackClick = useCallback(() => {
        const goBack = () => {
            if (wtrApp) Utils.postMessageToWTR({ message: 'tradePage' })
            else {
                if (userInsidePortal) {
                    history.push(routes.dashboard.accounts.root)
                } else {
                    history.push(routes.account.login)
                    if (loggedIn) dispatch(logout())
                }
            }
        }

        if (topLevelProfileRoutes.includes(location.pathname))
            history.push(routes.dashboard.accounts.root)
        else {
            const step = prevStep({})
            if (step) {
                if (loggedIn) {
                    if (step.step >= -1) history.push(step.url)
                    else goBack()
                }
                else
                    history.push(step.url) //create <-> personanal info
            }
            else goBack()
        }
    }, [location, history, wtrApp, userInsidePortal, loggedIn, dispatch, prevStep])

    return handleBackClick
}

const useRedirectToWTRhandler = (targetProp?: string) => {
    const target = targetProp || `${Math.random()}`
    const { userProfile, language } = useAppState()
    const dispatch = useDispatch()
    const { theme } = useThemes()

    const handleRedirectToWTR = useCallback(async (selectedAccount?: Account, targetMode?: 'LIVE' | 'DEMO', wtrAdditionalParams?: WtrAdditionalParams) => {
        try {
            const redirectUrl = `${appConfig.WTR_URL}/account/sso`
            const mode = selectedAccount ? selectedAccount.type : (targetMode || 'LIVE')
            const userEmail = wtrAdditionalParams?.email || userProfile?.email
            const response = await api.generateSsoToken()
            if (response.ssoCode) {
                const wtrLang = WTRLanguage[language]
                let url = `${redirectUrl}?ssoToken=${response.ssoCode}&email=${userEmail}&mode=${mode}&theme=${theme}&lang=${wtrLang}`
                if (wtrAdditionalParams) {
                    const { targetPage, status, transactionId, accountNumber } = wtrAdditionalParams
                    url += `&targetPage=${targetPage}&status=${status}&transactionId=${transactionId}&accountNumber=${accountNumber}`
                }
                window.open(url, target)
            }
        } catch (e: any) {
            dispatch(appError(Utils.customError(e.message)))
        }
    }, [userProfile, theme, language, target, dispatch])

    return handleRedirectToWTR
}

const useScreen = () => {
    const { screen } = useAppState()
    const notDesktop = useMemo(() => mobileDevice || screen !== ScreenSize.Desktop, [screen])
    const isDesktop = useMemo(() => !mobileDevice && screen === ScreenSize.Desktop, [screen])
    const isMobile = useMemo(() => mobileDevice || screen === ScreenSize.Mobile, [screen])
    const isTablet = useMemo(() => !mobileDevice && screen === ScreenSize.Tablet, [screen])
    return {
        notDesktop,
        isDesktop,
        isMobile,
        isTablet
    }
}

const useValidation = () => {
    const { t } = useTranslation('account')
    const { portalAccountDomain, userCountry } = useAppState()
    const isTMJP = portalAccountDomain === PortalAccountDomain.TMJP

    const required = useCallback((value: any) => {
        if (!value || (typeof value === 'string' && value.trim() === ''))
            return t('common:notDefined')
        return undefined
    }, [t])

    const makeRequiredValidator = useCallback((customError?: string) => {
        const error = customError || t('common:notDefined')
        return (value: any) => {
            if (!value || (typeof value === 'string' && value.trim() === ''))
                return error
            else return undefined
        }
    }, [t])

    const cardNumberLength = useCallback((value: string) => {
        return value && value.length === 4 ? undefined : t('common:cardNumberLength')
    }, [t])

    const validateKanji = useCallback((value?: string) => {
        if (value && !value.match(kanjiNameRegex)) {
            return t('common:kanjiOnly')
        }
    }, [t])

    const validateJapaneseSymbols = useCallback((value?: string) => {
        if (value && !value.match(allJapaneseSymbolsRegex)) {
            return t('common:japaneseSymbolsOnly')
        }
    }, [t])

    const validateKatakana = useCallback((value?: string) => {
        if (value && !value.match(katakanaNameRegex)) {
            return t('common:katakanaOnly')
        }
    }, [t])

    const minmaxChars = useCallback((value: any, options?: { min?: number, max?: number }) => {
        const { min, max } = options || {}
        if (min && max) {
            const testExpr = new RegExp(`^.{${min},${max}}$`)
            if (!testExpr.test(value)) return t('common:minmaxChars', { min: min, max: max })
        }
        else if (min) {
            const testExpr = new RegExp(`^.{${min},}$`)
            if (!testExpr.test(value)) return t('common:minChars', { min: min })
        }
        else if (max) {
            const testExpr = new RegExp(`^.{1,${max}}$`)
            if (!testExpr.test(value)) return t('common:maxChars', { max: max })
        }
        return undefined
    }, [t])


    const isAlphanumeric = useCallback((value: any, options?: { min?: number, max?: number }) => {
        if (!value) return t('common:notDefined')
        if (!/^[A-Za-z0-9]+$/.test(value)) return t('common:isAlphanumeric')
        return minmaxChars(value, options)
    }, [minmaxChars, t])

    const isNumeric = useCallback((value: any, options?: { min?: number, max?: number }) => {
        if (!value) return t('common:notDefined')
        if (!/^[0-9]+$/.test(value)) return t('common:isNumeric')
        if (options) {
            const { min, max } = options
            if (min && max) {
                const testExpr = new RegExp(`^[0-9]{${min},${max}}$`)
                if (!testExpr.test(value)) return t('common:minmaxChars', { min: min, max: max })
            }
            else if (min) {
                const testExpr = new RegExp(`^[0-9]{${min},}$`)
                if (!testExpr.test(value)) return t('common:minChars', { min: min })
            }
            else if (max) {
                const testExpr = new RegExp(`^[0-9]{1,${max}}$`)
                if (!testExpr.test(value)) return t('common:maxChars', { max: max })
            }
        }
        return undefined
    }, [t])

    const validateNumberOfDigits = useCallback((numOfDigits: number, value?: any, optional = false) => {
        if (!value && optional) return undefined
        else {
            const testExpr = new RegExp(`^[0-9]{${numOfDigits}}$`)
            if (!testExpr.test(value)) return t('common:id.notValidLength', { numOfDigits: numOfDigits })
            return undefined
        }
    }, [t])

    const validateID = useCallback((value?: any) => {
        if (value && !/^\d$/.test(value!)) return t('common:id.notValid')
    }, [t])

    const validateAustralianTFX = useCallback((value?: string, optional = false) => {
        return validateNumberOfDigits(9, value, optional)
    }, [validateNumberOfDigits])

    const validateACN = useCallback((value?: string, optional = false) => {
        return validateNumberOfDigits(9, value, optional)
    }, [validateNumberOfDigits])

    const validateUKInsuranceNumber = useCallback((value?: string, optional = false) => {
        if (!value) return t('common:notDefined')
        return !/^[a-zA-Z]{2}(?:\s*\d\s*){6}[a-zA-Z]$/.test(value)
            ? t('common:invalidUKInsuranceNumber')
            : undefined
    }, [t])

    const validatePhone = useCallback((phoneValue?: Phone) => {
        const shortNumber = userCountry?.code3 === "AND" ||
            userCountry?.code3 === "NCL"
        if (phoneValue && phoneValue.number) {
            const maxDigits = shortNumber ? 6 : 7
            const phoneNumber = parsePhoneNumberFromString('+' + phoneValue.code + phoneValue.number)
            if (!phoneNumber) return t('common:phone.invalid')
            if (phoneNumber.nationalNumber.length < maxDigits) return t('common:phone.short', { digits: maxDigits })
            if (!phoneNumber.isPossible()) return t('common:phone.notExists')
        } else {
            return t('common:phone.pleaseEnter')
        }
    }, [t, userCountry?.code3])

    const passwordTesters = {
        matchUppercase: /^.*[A-Z]+/,
        matchLowercase: /^.*[a-z]+/,
        matchNumbers: /^.*[0-9]+/,
        matchSpecialSymbols: /^.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]+/,
    }

    const chineseRegex = /[^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFF]+/g

    const validateName = useCallback((value?: string, country?: Country) => {
        if (!value) return t('common:notDefined')
        if (passwordTesters.matchSpecialSymbols.test(value)) return t('common:invalidChars')
        if (passwordTesters.matchNumbers.test(value)) return t('common:invalidChars')
        if (!country) return ''
        if (Utils.checkIfCountryIsChina(country) && value.match(chineseRegex)) {
            return t('account:firstName.chineseOnly')
        }
        return undefined
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t])

    const validateEmail = useCallback((email: string) => {
        if (!email)
            return t('common:email.errors.notDefined')
        if (!Utils.isValidEmail(email))
            return t('common:email.errors.notValid')
        return undefined
    }, [t])

    const validateWebsite = useCallback((value: string) => {
        if (!value)
            return t('common:website.errors.notDefined')
        if (!value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g))
            return t('common:website.errors.notValid')
        return undefined
    }, [t])

    const validateDate = useCallback((date?: Date) => {
        if (!date) return t('common:notDefined')
        if (!moment(date).isValid())
            return t('common:invalidDate')
        return undefined
    }, [t])

    const validateDOB = useCallback((dob?: Date) => {
        const minimumAge = isTMJP ? 20 : 18
        const maximumAge = isTMJP ? 80 : 100
        const invalid = validateDate(dob)
        if (invalid) return invalid
        const date = moment(dob)
        const now = moment()
        const diff = now.diff(date, 'years')
        if (now.isBefore(date)) return t('common:dateInFuture')
        if (diff < minimumAge) return t('account:birthDate.under18')
        if (diff > maximumAge) return t('account:birthDate.moreThan100yo')
        return undefined
    }, [t, validateDate, isTMJP])

    const validateDateNotInFuture = useCallback((date?: Date) => {
        const invalid = validateDate(date)
        if (invalid) return invalid
        if (moment(date).isAfter(moment()))
            return t('common:dateInFuture')
        return undefined
    }, [t, validateDate])

    const validateOptionalName = useCallback((value: string) => {
        if (!value) return t('common:notDefined')
        if (passwordTesters.matchSpecialSymbols.test(value)) return t('common:invalidChars')
        return undefined
    }, [passwordTesters.matchSpecialSymbols, t])

    const validateCountry = useCallback((value: Country) => {
        if (!value) return t('account:country.placeholder')
    }, [t])

    return {
        required,
        cardNumberLength,
        isAlphanumeric,
        isNumeric,
        validateNumberOfDigits,
        validateAustralianTFX,
        validateID,
        validatePhone,
        validateName,
        validateEmail,
        passwordTesters,
        chineseRegex,
        validateJapaneseSymbols,
        validateKanji,
        validateKatakana,
        validateDOB,
        validateDate,
        validateWebsite,
        validateOptionalName,
        validateDateNotInFuture,
        validateCountry,
        makeRequiredValidator,
        validateACN,
        minmaxChars,
        validateUKInsuranceNumber
    }
}

const useAppState = () => {
    return useSelector((state: Store) => state.App)
}

const useQuestions = (app: AppInfo | null): QuestionsIDs[] => {
    const q = useRef(app && app.accountApplicationQuestionDetails
        ? app.accountApplicationQuestionDetails : [])
    return q.current
}

const useYesNoOptions = () => {
    const { t } = useTranslation()

    return useMemo(() =>
        [
            { name: t(`common:yes`), value: true },
            { name: t(`common:no`), value: false }
        ], [t])
}


const useAccountTypeString = () => {
    const { t } = useTranslation()
    const { portalAccountDomain } = useAppState()
    const isAU = portalAccountDomain === PortalAccountDomain.AU
    return useCallback((account?: AccountTradingTypeUnion, country?: Country | null) => {
        if (!account) return ''
        const contryString = isAU ? "account:accountTradingType.AUSNZL" : 'account:accountTradingType.ZAF'
        if (account === 'CE') {
            return t(`${contryString}.Shares`)
            // DEBUG
            // } else if (account === 'CFD-COPY') {
            //     return t(`${contryString}.cfdCopy`)
        } else {
            return t(`${contryString}.ForexAndCFDs`)
        }
    }, [isAU, t])
}

const useApplicationTypeString = () => {
    const typeString = useAccountTypeString()
    return useCallback((app?: UserApplication) => {
        return app
            ? typeString(app.accountTradingType.accountTradingType, app.country!)
            : null
    }, [typeString])
}


const useQuery = () => {
    return new URLSearchParams(useLocation().search)
}

type FormFields = AddressFields & {
    accountHolderFirstName?: FieldValidation
    accountHolderLastName?: FieldValidation
    accountHolderDayOfBirth?: FieldValidation
    accountHolderMonthOfBirth?: FieldValidation
    accountHolderYearOfBirth?: FieldValidation
    originCountry?: FieldValidation
    accountHolderTitle?: FieldValidation
    accountHolderMiddleName?: FieldValidation
    accountHolderMobilePhone?: FieldValidation
    accountHolderMobilePhoneCode?: FieldValidation
    accountHolderPhoneCode?: FieldValidation
    accountHolderPhone?: FieldValidation
    accountHolderIdNumber?: FieldValidation
}

type FormFieldsIndexed = FormFields & {
    [key: string]: FieldValidation
}

const useAppRules = (country_id?: number) => {
    const [appRules, setAppRules] = useState<FormFields>()
    const { uncompletedApplication } = useAppState()

    useEffect(() => {
        let mounted = true

        const getValidationRules = async (country_id: number) => {
            const response = await api.getAppRules(country_id, uncompletedApplication?.accountType)
            if (response.payload[0].status === 'OK') {
                const rules = response.payload[0].result
                if (mounted) {
                    const data: FormFieldsIndexed = {}
                    rules.fields.forEach(r => (data[r.fieldName] = { optional: r.optional }))
                    //always show phone
                    data.accountHolderPhone = { optional: false }
                    if (data.accountHolderMobilePhone) delete data.accountHolderMobilePhone
                    setAppRules(data)
                }
            }
        }

        if (country_id) {
            getValidationRules(country_id)
        }

        return () => { mounted = false }
    }, [country_id, uncompletedApplication?.accountType])

    return { appRules }
}

const useAccountVerifiedEvent = () => {
    const { accountsLoaded, userProfile } = useAppState()
    const { latestLiveAccount } = useAccounts()
    const checked = useRef(false)
    useEffect(() => {
        if (accountsLoaded && latestLiveAccount && !checked.current) {
            checked.current = true
            if (latestLiveAccount.tradeNumber === 0 && latestLiveAccount.balance === 0) {
                window.dataLayer?.push({
                    'event': 'Account Verified',
                    'user id': userProfile?.id
                })
            }
        }
    }, [accountsLoaded, latestLiveAccount, userProfile?.id])
}

function useClickOutside<T extends HTMLElement>(handler: () => void) {
    const ref = useRef<T>(null)

    useEffect(() => {
        const handleMouseDown = (event: MouseEvent) => {
            if (!ref.current || ref.current.contains(event.target as Node)) {
                return
            }
            handler()
        }
        document.addEventListener('mousedown', handleMouseDown)
        return () => {
            document.removeEventListener('mousedown', handleMouseDown)
        }
    }, [handler])

    return { ref }
}

const useLanguageID = () => {
    const { preferredLanguages, language } = useSelector((state: Store) => state.App, shallowEqual)

    const getLanguageId = useCallback((country?: Country) => {
        let preferredLanguage = 1
        if (preferredLanguages) {
            let lang = preferredLanguages.find(l => {
                return country?.organization.name === PortalAccountDomain.TMJP
                    ? l.language_code === 'ja'
                    : l.language_code === language
            })
            if (lang) preferredLanguage = lang.id
        }
        return preferredLanguage
    }, [language, preferredLanguages])

    return getLanguageId
}

const useDangerousHTML = () => {
    const p = useCallback((html: string, className?: string) =>
        <p className={className} dangerouslySetInnerHTML={{
            __html: html
        }} />, [])
    const d = useCallback((html: string, className?: string) =>
        <div className={className} dangerouslySetInnerHTML={{
            __html: html
        }} />, [])
    const s = useCallback((html: string, className?: string) =>
        <span className={className} dangerouslySetInnerHTML={{
            __html: html
        }} />, [])
    return { p, d, s }
}

const useInterval = (action: () => void, interval: number) => {
    useEffect(() => {
        const int = setInterval(action, interval)
        return () => { clearInterval(int) }
    }, [action, interval])
}

const useTimeout = (action: () => void, interval: number) => {
    useEffect(() => {
        const int = setTimeout(action, interval)
        return () => { clearTimeout(int) }
    }, [action, interval])
}

const useRenderLink = () => {
    return {
        renderLink: useCallback((url: string, text: string, className?: string) => {
            return renderToString(<HttpLink url={url} className={className}>
                {text}
            </HttpLink>)
        }, [])
    }
}

const useSelectPlatform = (type: TradeAccountType, flow: 'onboarding' | 'additional') => {
    const { userProfile } = useAppState()
    const { platforms, multiTypeTT } = usePlatforms(type)
    const getCurrencyDescription = useCurrencyName()

    const showPlatformDetails = useCallback((platformName?: TradingPlatformName | TradingPlatformID) => {
        switch (platformName) {
            case 'THINK_COPY': return false
            case 'ThinkTrader': return (flow === 'additional') || multiTypeTT
            default: return !!platformName
        }
    }, [flow, multiTypeTT])

    const showPlatformCurrency = useCallback((platformName?: TradingPlatformName | TradingPlatformID,
        accountType?: TradingAccountType) => {
        return showPlatformDetails(platformName)// && !!accountType
    }, [showPlatformDetails])

    const showPlatformLeverage = useCallback((platformName?: TradingPlatformName | TradingPlatformID,
        accountType?: TradingAccountType) => {
        return showPlatformCurrency(platformName, accountType)
    }, [showPlatformCurrency])

    const validCurrencyForPlatform = useCallback((
        platformName?: TradingPlatformName | TradingPlatformID,
        currency?: string,
        accountType?: TradingAccountType) => {
        if (!currency || !platformName) return false
        const platform = platforms.find(p => p.name === platformName)
        const platformCurrencies = platform?.accounts.find(a => a.type === accountType)?.currencies || []
        return platformCurrencies.indexOf(currency) !== -1
    }, [platforms])


    const defaultCurrency = useCallback((platformName: TradingPlatformName | TradingPlatformID,
        currency: string,
        accountType?: TradingAccountType) => {
        if (validCurrencyForPlatform(platformName, currency, accountType))
            return {
                name: getCurrencyDescription(currency),
                value: currency
            }
        else return undefined
    }, [validCurrencyForPlatform, getCurrencyDescription])

    const defaultLeverage = useCallback((accountType?: TradingAccountType) => {
        if (accountType === 'Mini') return 2000
        const organization = userProfile?.organization
        return Utils.leverageString2Number(organization?.defaultLeverage)
    }, [userProfile])

    const defaultLeverageOption = useCallback((accountType?: TradingAccountType) => {
        const l = defaultLeverage(accountType)
        return {
            name: `1:${l}`,
            value: l
        }
    }, [defaultLeverage])

    return {
        validCurrencyForPlatform,
        defaultLeverage,
        defaultLeverageOption,
        defaultCurrency,
        showPlatformDetails,
        showPlatformCurrency,
        showPlatformLeverage
    }
}

const useOrganizationChanged = () => {
    const { userProfile, portalAccountDomain } = useAppState()
    const isSAatTMSY = Utils.checkIfCountryIsSA(userProfile?.country) &&
        portalAccountDomain === PortalAccountDomain.TMSY
    return { isSAatTMSY }
}

const usePixelTracking = () => {
    const { userProfile, userCountry, language } = useAppState()

    const isArabic = useMemo(() => {
        const countries = ["SAU", "ARE", "OMN", "CAT", "JOR", "BHR", "KWT", "EGY", "LBN",]
        const code = userProfile?.country.code3 || userCountry?.code3 || ''
        return language === 'ar' ||
            countries.includes(code)
    }, [language, userCountry?.code3, userProfile?.country.code3])

    const start = useMemo(() =>
        isArabic
            ? <img src="https://px.adentifi.com/Pixels?a_id=6917;uq=[CACHEBUSTER];"
                height="0" width="0" style={{ display: "none", border: 0 }} alt="start" />
            : null
        , [isArabic])

    const submit = useMemo(() =>
        isArabic
            ? <img src="https://px.adentifi.com/Pixels?a_id=6916;uq=[CACHEBUSTER];"
                height="0" width="0" style={{ display: "none", border: 0 }} alt="submit" />
            : null
        , [isArabic])

    return { start, submit }
}

const useQnAforJPNapplication = (questionID: number) => {
    const dispatch = useDispatch()
    const { uncompletedApplication: appInfo, questions } = useAppState()
    const qs = useQuestions(appInfo)
    const questionObject = useMemo(() => questions?.find(x => x.id === questionID), [questionID, questions])

    useEffect(() => {
        if (!questions || questions.length === 0) dispatch(getQuestions())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAccountAppQsDetails = useCallback((answerValue?: boolean) => {
        const cleanedQs = [...qs].filter(x => ![questionID].includes(x.question || 0))
        const answerObject = questionObject?.answers.find(x => x.label === (answerValue ? 'yes' : 'no'))
        const accountApplicationQuestionDetails = [...cleanedQs, { question: questionID, answer: (answerObject?.id || 0) }]
        return accountApplicationQuestionDetails
    }, [qs, questionID, questionObject])

    return { getAccountAppQsDetails }
}

const usePAMMOptions = () => {
    ///THPRT-5289 remove PAMM
    // const { hasPAMMInvestor, PAMMMasterLimitReached } = useAccounts()
    // const { userProfile } = useAppState()

    const allowPAMM = useMemo(() => {
        // const hasFullPamm = hasPAMMInvestor && PAMMMasterLimitReached
        // return !hasFullPamm && (userProfile?.organization.name === "TMBM" || userProfile?.organization.name === "TMLC")
        //     ? true : false
        return false
    }, [])
    return allowPAMM
}

const useSimplifyOnboardingCheck = () => {
    const { uncompletedApplication, userProfile, appStatus } = useAppState()

    const checkForExSimplifyOnboarding = useCallback((accountType?: TradingAccountType) => {
        const accType = accountType || uncompletedApplication.platformAccountType
        const accCountry = userProfile?.country

        return accCountry?.isSimplifyOnboarding && accType === 'PAMM_Master'
    }, [uncompletedApplication.platformAccountType, userProfile?.country])

    const checkForSimplifiedOnboarding = useCallback((userCountry?: Country, accountType?: TradingAccountType) => {
        const accType = accountType || uncompletedApplication.platformAccountType
        const accCountry = userCountry || userProfile?.country

        if (accCountry?.isSimplifyOnboarding) {
            if (accType === 'PAMM_Master') {
                return false
            }

            return true
        }

        return false
    }, [uncompletedApplication.platformAccountType, userProfile?.country])

    const checkIfPendingReview = useMemo(() => {
        return appStatus === 'PENDING_REVIEW' || appStatus === 'PENDING_E_VERIFICATION'
    }, [appStatus])

    const checkIfPendingDocs = useMemo(() => {
        const pendingDocsStatuses: ApplicationStatus[] = ['MANUAL_DOCS', 'PENDING_KYC', 'PENDING_E_VERIFICATION_RETRY']
        return appStatus && pendingDocsStatuses.includes(appStatus)
    }, [appStatus])

    return {
        checkForExSimplifyOnboarding,
        checkIfPendingReview,
        checkIfPendingDocs,
        checkForSimplifiedOnboarding
    }
}

const useOrganization = () => {
    const { userProfile, portalAccountDomain, onboardingDomain, userCountry } = useAppState()

    const domain = useMemo(() => {
        if (userProfile) return userProfile.organization.name
        return onboardingDomain || portalAccountDomain || userCountry?.organization.name
    }, [onboardingDomain, portalAccountDomain, userCountry, userProfile])

    const isAU = useMemo(() => { return domain === PortalAccountDomain.AU }, [domain])
    const isUK = useMemo(() => { return domain === PortalAccountDomain.UK }, [domain])
    const isTFSA = useMemo(() => { return domain === PortalAccountDomain.TFSA }, [domain])
    const isTMBM = useMemo(() => { return domain === PortalAccountDomain.TMBM }, [domain])
    const isTMLC = useMemo(() => { return domain === PortalAccountDomain.TMLC }, [domain])
    const isTMSY = useMemo(() => { return domain === PortalAccountDomain.TMSY }, [domain])
    const isTMCY = useMemo(() => { return domain === PortalAccountDomain.TMCY }, [domain])
    const isTMEU = useMemo(() => { return domain === PortalAccountDomain.TMEU }, [domain])
    const isTMJP = useMemo(() => { return domain === PortalAccountDomain.TMJP }, [domain])
    const isTMNZ = useMemo(() => { return domain === PortalAccountDomain.TMNZ }, [domain])

    return {
        isAU,
        isUK,
        isTFSA,
        isTMBM,
        isTMLC,
        isTMSY,
        isTMCY,
        isTMEU,
        isTMJP,
        isTMNZ,
    }
}

const usePlatformAvailability = () => {
    const { hasThinkCopy: hasTCApplication, simplifiedAppNotApprovedYet } = useUserApplications()
    const { isTMBM, isTMSY, isTMLC } = useOrganization()
    const { hasThinkCopy } = useAccounts()

    const isThinkCopyPlatformEnabled = useMemo(() => {
        return isTMBM || isTMSY || isTMLC
    }, [isTMBM, isTMLC, isTMSY])

    const isThinkCopyBannerEnabled = useMemo(() => {
        if (simplifiedAppNotApprovedYet) return false
        return isThinkCopyPlatformEnabled && !(hasThinkCopy || hasTCApplication)
    }, [hasTCApplication, hasThinkCopy, isThinkCopyPlatformEnabled, simplifiedAppNotApprovedYet])

    return {
        isThinkCopyPlatformEnabled,
        isThinkCopyBannerEnabled
    }
}

const usePlatforms = (demoLive: TradeAccountType = TradeAccountType.Live, ttOnly?: boolean) => {
    const { demoPlatforms, livePlatforms, userCountry, isUKSB } = useAppState()
    const isLive = demoLive === TradeAccountType.Live
    const allowPAMM = usePAMMOptions()
    const { isThinkCopyPlatformEnabled } = usePlatformAvailability()
    const { isCapitalIndex } = useIntroducer()

    const sortOrder: Record<TradingPlatformName, number> = useMemo(() => {
        // Specific order for Vietnam, THPRT-5084
        if (userCountry?.code3 === 'VNM') {
            return {
                "MT4": 1,
                "MT5": 2,
                "ThinkTrader": 3,
                "THINK_COPY": 4,
                "PAMM_Trading": 5,
                "PAMM_TRADING": 6,
                "ThinkInvest": 7,
                "TradeInterceptor": 8,
            }
        }

        if (isCapitalIndex()) {
            return {
                "MT4": 1,
                "ThinkTrader": 2,
                "MT5": 3,
                "THINK_COPY": 4,
                "PAMM_Trading": 5,
                "PAMM_TRADING": 6,
                "ThinkInvest": 7,
                "TradeInterceptor": 8,
            }
        }

        return {
            "ThinkTrader": 1,
            "THINK_COPY": 2,
            "MT4": 3,
            "MT5": 4,
            "PAMM_Trading": 5,
            "PAMM_TRADING": 5,
            "ThinkInvest": 6,
            "TradeInterceptor": 7,
        }
    }, [isCapitalIndex, userCountry])

    const allowedPlatforms = useMemo(() => {
        let platforms = isLive
            ? [...livePlatforms]
            : demoPlatforms.filter(p => p.name.toUpperCase() !== 'PAMM_TRADING' &&
                p.name !== 'THINK_COPY')
        if (!isThinkCopyPlatformEnabled)
            platforms = platforms.filter(p => p.name !== 'THINK_COPY')
        if (!allowPAMM)
            platforms = platforms.filter(p => p.name.toUpperCase() !== 'PAMM_TRADING')
        platforms.sort((a, b) => {
            return sortOrder[a.name] - sortOrder[b.name]
        })
        return platforms
    }, [allowPAMM, demoPlatforms, isLive, isThinkCopyPlatformEnabled, livePlatforms, sortOrder])

    const TT = useMemo(() => {
        return allowedPlatforms.find(p => p.name === 'ThinkTrader')
    }, [allowedPlatforms])

    const multiTypeTT = useMemo(() => {
        return TT && TT.accounts.length > 1
    }, [TT])

    const platforms = useMemo(() => {
        if (ttOnly)
            return allowedPlatforms.filter(p => p.name === 'ThinkTrader')
        if (isUKSB) {
            const sbPlatforms: TradingPlatformName[] = ['MT4', 'MT5']
            if (multiTypeTT) sbPlatforms.push('ThinkTrader')
            return allowedPlatforms.filter(p => sbPlatforms.includes(p.name))
        }
        return allowedPlatforms
    }, [ttOnly, allowedPlatforms, isUKSB, multiTypeTT])

    return { platforms, TT, multiTypeTT }
}

const useDebounce = (callback: Function) => {
    const ref = useRef<Function>()

    useEffect(() => {
        ref.current = callback
    }, [callback])

    const debouncedCallback = useMemo(() => {
        const func = () => {
            ref.current?.()
        }

        return debounce(func, 500)
    }, [])

    return debouncedCallback
}

const useRemainingDeposit = (stats?: AccountStat) => {
    const [deposit, setDeposit] = useState<RemainingDeposit>({
        netSumOfAppliedDeposits: 0,
        netMinDepositAmount: 0,
        currencyCode: ""
    })
    const [checked, setChecked] = useState(false)

    const shouldCheck = useMemo(() => {
        return stats
            ? stats.account.type === TradeAccountType.Live &&
            stats.platformAccountType === 'Mini'
            : false
    }, [stats])

    useEffect(() => {
        if (stats) {
            const checkDeposit = async () => {
                try {
                    const response = await api.getRemainigDeposit(stats.account.id)
                    api.checkTFBOResponse(response)
                    setDeposit(response.payload[0].result)
                    setChecked(true)
                } catch { }
            }
            if (shouldCheck) checkDeposit()
            else setChecked(true)
        }
    }, [shouldCheck, stats])

    const deposited = useMemo(() => {
        return shouldCheck && checked
            ? deposit.netSumOfAppliedDeposits >= deposit.netMinDepositAmount
            : true
    }, [checked, deposit, shouldCheck])

    const remainingSum = useMemo(() => {
        return checked
            ? `${(deposit.netMinDepositAmount - deposit.netSumOfAppliedDeposits).toFixed(2)} ${deposit.currencyCode}`
            : null
    }, [deposit, checked])

    return {
        remainigDeposit: deposit,
        shouldCheck,
        deposited,
        remainingSum
    }
}

const usePlatformAccountTypeOptions = (
    platform: TradingPlatform | undefined,
    country?: Country | undefined,
    additionalAccount: boolean = false) => {
    const { t } = useTranslation()
    const { allowThinkInvest, userProfile, isUKSB } = useAppState()
    const { hasPAMMInvestor, PAMMMasterLimitReached } = useAccounts()
    const { accountTypeOrder } = useSortedAccounts()
    const allowPAMM = usePAMMOptions()
    const { isCapitalIndex } = useIntroducer()

    const noThinkInvest = useMemo(() => {
        const isSA = Utils.checkIfCountryIsSA(userProfile?.country)
        switch (true) {
            case isSA:
                return true
            case !additionalAccount:
                return allowPAMM
            case additionalAccount && !allowThinkInvest: return true
            default: return false
        }
    }, [additionalAccount, allowPAMM, allowThinkInvest, userProfile])

    return useMemo<SelectOption<TradingAccountType>[]>(() => {
        if (!platform) return []
        let accounts = platform.accounts
        const pamm = platform.name.toUpperCase() === "PAMM_TRADING"
        if (platform.name === 'MT4' && isCapitalIndex())
            accounts = accounts.filter(a => a.type === 'standard')
        const types = accounts.map(
            a => {
                const { type } = a
                const disabled = pamm
                    ? (type === 'PAMM_Investor' && hasPAMMInvestor) ||
                    (type === 'PAMM_Master' && PAMMMasterLimitReached)
                    : false
                return {
                    name: t(`common:accountType.values.${type}`),
                    description: t(`accounts:${platform.name}.${type}`),
                    value: type,
                    isDisabled: disabled
                }
            }
        )
        let result = types.filter(t => !t.isDisabled)
        const noMini = (platform?.name === 'MT4') || (country && NON_MINI_TMBM_COUNTRIES.includes(country.code3))
        if (noMini)
            result = result.filter(t => !(t.value === 'Mini'))

        result.sort((a, b) => accountTypeOrder[a.value] - accountTypeOrder[b.value])
        return noThinkInvest
            ? result.filter(t => t.value !== 'think_invest')
            : isUKSB
                ? result.filter(t => t.value === 'spreadBetting')
                : result
    }, [PAMMMasterLimitReached, accountTypeOrder, country, hasPAMMInvestor, isCapitalIndex, isUKSB, noThinkInvest, platform, t])
}

const useEmbeddedLink = ({
    text,
    onClick,
    className,
    linkClassName = 'link'
}: {
    text: string
    onClick: () => void
    className?: string
    linkClassName?: string
}
) => {
    const click = useCallback((event) => {
        if (event.target.nodeName === 'SPAN') {
            onClick()
        }
    }, [onClick])

    return useMemo(() => {
        const mask = new RegExp('\{%(.*?)%\}', 'g')
        const matches = mask.exec(text)
        const __html = text.replace(mask, _ => {
            return matches && matches.length > 1
                ? `<span class="${linkClassName}">${matches[1]}</span>`
                : text
        })
        return <div onClick={click} className={className} dangerouslySetInnerHTML={{ __html }} />
    }, [className, click, linkClassName, text])
}

const useLandingPage = () => {
    const { landingPage } = useAppState()
    const { application: ibApplication } = useIBState()
    const sidebarItems = useMenuEntries()
    const url = useMemo(() => {
        const isCorrectPath = (path?: string) => {
            if (path && path.startsWith(ibPath) && !ibApplication) return false
            const allowed = {
                ...Utils.deepFlattenToObject(routes.dashboard, 'd'),
                ...Utils.deepFlattenToObject(routes.ib, 'i'),
                ...Utils.deepFlattenToObject(routes.profile, 'p')
            }
            return Object.values(allowed).some(v => v === path)
        }
        const isCorrectLanding = (path?: string) => {
            let result = true
            const check = (items: DashboardMenuItem[]) => {
                items.forEach(i => {
                    if (!result) return
                    if (i.children) check(i.children)
                    if (i.link === path &&
                        ((i.shouldDisplay && i.shouldDisplay() === false) ||
                            i.disabled)) {
                        result = false
                    }
                })
            }
            check(sidebarItems)
            return result
        }
        if (landingPage &&
            isCorrectPath(landingPage.path)
            && isCorrectLanding(landingPage.path)) {
            return landingPage.query
                ? `${landingPage.path}?${landingPage.query}`
                : landingPage.path
        }
        return routes.dashboard.accounts.root
    }, [ibApplication, landingPage, sidebarItems])
    return url
}

const useShowTradingView = () => {
    const { userProfile } = useAppState()
    const { isDesktop } = useScreen()
    const { isTMJP } = useOrganization()
    const isTW = userProfile?.country.code3 === "TWN"
    const isHK = userProfile?.country.code3 === "HKG"
    return isDesktop && !(isTW || isHK || isTMJP)
}

const useShowThinkCapital = () => {
    const { isTMLC, isTMBM } = useOrganization()
    const { userProfile } = useAppState()
    const code = userProfile?.country.code3 || ""
    return (isTMLC || isTMBM) && !["EGY", "VNM"].includes(code)
}

const useCreateLiveTT = () => {
    const history = useHistory()
    const { hasNoApplication } = useUserApplications()
    const dispatch = useDispatch()

    return useCallback(() => {
        if (hasNoApplication) {
            dispatch(setUserAppInfo({
                accountType: 'individual'
            }))
            history.push(routes.account.createAccount.live)
        }
        else history.push({
            pathname: routes.dashboard.accounts.createLive,
            state: {
                platform: 'ThinkTrader',
                accountType: 'ThinkTrader'
            }
        })
    }, [dispatch, hasNoApplication, history])
}

const useIntroducer = () => {
    const { userProfile } = useAppState()
    const { introducedBy } = useUserApplications()

    const introducer = useMemo(() => {
        return userProfile?.additionalAttributes?.introducedBy ||
            localStorage.getItem(__STORAGE.introducedBy) ||
            introducedBy
    }, [introducedBy, userProfile])

    const isCapitalIndex = useCallback((ib?: string) => {
        const pid = ib || introducer
        if (pid) {
            return Utils.isTestEnv()
                ? pid === '10609073'
                : pid === '289519'
        }
        return false
    }, [introducer])

    // return true if capital index
    const checkOnboardingIntroducer = useCallback(() => {
        const ib = Utils.getIBcookie()
        if (ib && isCapitalIndex(ib.pid)) {
            localStorage.setItem(__STORAGE.introducedBy, ib.pid)
            return true
        }
        return false
    }, [isCapitalIndex])

    return { introducer, checkOnboardingIntroducer, isCapitalIndex }
}

export {
    useSimplifyOnboardingCheck,
    usePrevious,
    useShortTranslation,
    useScrollToTop,
    usePageTitle,
    useCurrencyFormatter,
    useFormatAmount,
    UseLinks,
    useIsGeneralSidebarEnabled,
    usePlatformName,
    use2FACheck,
    useCompleteAppLaterButton,
    useGetAccountTypesOptions,
    useUserApplications,
    useAccounts,
    useEmailValidation,
    useSetLastFieldName,
    useBackButton,
    useSortedAccounts,
    useValidation,
    useAppState,
    useQuestions,
    useApplicationTypeString,
    useAccountTypeString,
    useQuery,
    useAppRules,
    useYesNoOptions,
    useAccountVerifiedEvent,
    useThousandsFormatter,
    useClickOutside,
    useDateTimeFormatter,
    useLanguageID,
    useApplicationAccountType,
    useRedirectToWTRhandler,
    useScreen,
    useDangerousHTML,
    useInterval,
    useTimeout,
    useRenderLink,
    useSelectPlatform,
    useOrganizationChanged,
    usePixelTracking,
    useQnAforJPNapplication,
    usePAMMOptions,
    useOrganization,
    usePlatformAvailability,
    useDebounce,
    usePlatforms,
    useRemainingDeposit,
    usePlatformAccountTypeOptions,
    useEmbeddedLink,
    useLandingPage,
    useShowTradingView,
    useShowThinkCapital,
    useCreateLiveTT,
    useIntroducer
}

